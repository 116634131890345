import {handleAddToCartAndSyncApp} from "../../common/appShoppingCart";
import {useOverlay} from "../../common/step0Overlay";

class WarenkorbButtonArtikelfokus extends HTMLElement {
    static get observedAttributes() {
        return ["data-state"];
    }

    connectedCallback() {
        this.declareElements();

        if (this.isApp === true) {
            this.setSubmitListener();
        }
        if (this.isApp != true) {
            const artikelnummer = this.getAttribute("artikel-nummer");
            useOverlay(this, artikelnummer);
        }
    }

    attributeChangedCallback(attributename, oldValue, newValue) {
        if (oldValue === newValue) {
          return;
        }
        if (attributename === "data-state") {
          this.changeButtonState(newValue);
        }
    }

    declareElements() {
        this.isApp = (this.getAttribute("is-app") === "true");
        this.form = this.querySelector("form");
        this.artikelnummer = [ this.querySelector("[name=artikelnummer]").value ];
    }

    setSubmitListener() {
        this.form.addEventListener("submit", (event) => handleAddToCartAndSyncApp(event, this.artikelnummer));
    }

    changeButtonState(newState) {
      const wkButton = this.querySelector("button");
      const icon = this.querySelector(".icon-warenkorb-button");
      if (newState === "loading") {
        icon.classList.remove("element-icon-warenkorb-hinzufuegen");
        icon.classList.remove("element-icon-haekchen");
        icon.classList.add("element-icon-ladeindikator");
        wkButton.setAttribute("icon", "loading");
        this.resetButtonState(wkButton, 5000);
      } else if (newState === "success") {
        icon.classList.remove("element-icon-warenkorb-hinzufuegen");
        icon.classList.remove("element-icon-ladeindikator");
        icon.classList.add("element-icon-haekchen");
        wkButton.setAttribute("icon", "checkmark");
        this.resetButtonState(wkButton, icon, 2000);
      }
    }

    resetButtonState(wkButton, icon, time) {
      setTimeout(() => {
        icon.classList.remove("element-icon-ladeindikator");
        icon.classList.remove("element-icon-haekchen");
        icon.classList.add("element-icon-warenkorb-hinzufuegen");
        wkButton.setAttribute("icon", "cart");
      }, time);
    }
  }

  if (!customElements.get("warenkorb-button-artikelfokus")) {
    customElements.define("warenkorb-button-artikelfokus", WarenkorbButtonArtikelfokus);
  }
